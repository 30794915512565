import { React } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import OurServices from "./components/pages/OurServices";
import Team from "./components/pages/Team";
import ContactUs from "./components/pages/ContactUs";
import ScrollToTop from "./components/layout/ScrollToTop";


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* <Route path="/" element={<Navigate to="/the-comeback-home" />} exact /> */}
        <Route path="/" element={<Home />} exact />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;