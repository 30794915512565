import React from 'react'

import ContactUsSection1 from "./ContactUsSection1"
import ContactUsSection2 from "./ContactUsSection2"
import FooterAuthor from "../../layout/FooterAuthor"
export default function Content() {
    return (
        <>
            <ContactUsSection1 />
            <ContactUsSection2 />
            <FooterAuthor />
        </>
    )
}
