import React, { useEffect } from 'react'
import TheCBHomeMainBanner from "./TheCBHomeMainBanner"
import FooterAuthor from "../../layout/FooterAuthor"
import Facilities from "./Facilities"
import CheckAvailibilityForm from "./CheckAvailibilityForm"
import CheckAvailibilityphotoGallery from "./CheckAvailibilityphotoGallery"
import Testimonials from "./Testimonials"

export default function Content() {

    return (
        <>
            <TheCBHomeMainBanner />
            <Facilities />
            <CheckAvailibilityForm />
            <CheckAvailibilityphotoGallery />
            <Testimonials />
            <FooterAuthor />
        </>
    )
}
