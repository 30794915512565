import React from 'react'
import profile1 from "../../../assets/images/profile-1.jpg";
import profile3 from "../../../assets/images/profile-3.jpg";

export default function OurTeamSection() {
    return (
        <>
            <section className="team-section">
                <div className="container">

                    <div className="row text-center">

                        <div className="col-lg-4 col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="profile">
                                        <img src={profile1} alt="" />
                                    </div>
                                    <div className="detail">
                                        <h4>Travis Winslow</h4>
                                        <h6>Manager</h6>
                                        <div className="social">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="profile">
                                        <img src={profile3} alt="" />
                                    </div>
                                    <div className="detail">
                                        <h4>Joshuah Paulsen</h4>
                                        <h6>Owner</h6>
                                        <div className="social">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="profile">
                                        <img src={profile1} alt="" />
                                    </div>
                                    <div className="detail">
                                        <h4>Erin Skinner</h4>
                                        <h6>Owner</h6>
                                        <div className="social">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center mb-5">
                        <div className="col-lg-4 col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="profile">
                                        <img src={profile1} alt="" />
                                    </div>
                                    <div className="detail">
                                        <h4>Travis Winslow</h4>
                                        <h6>Manager</h6>
                                        <div className="social">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="profile">
                                        <img src={profile3} alt="" />
                                    </div>
                                    <div className="detail">
                                        <h4>Joshuah Paulsen</h4>
                                        <h6>Owner</h6>
                                        <div className="social">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card">
                                <div className="content">
                                    <div className="profile">
                                        <img src={profile1} alt="" />
                                    </div>
                                    <div className="detail">
                                        <h4>Erin Skinner</h4>
                                        <h6>Owner</h6>
                                        <div className="social">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-linkedin"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
