import React, { useEffect } from 'react'
import IconGallery from "../../../assets/images/IconGallery.svg";
// import testimonials01 from "../../../assets/images/testimonials-01.jpg";
import testimonials02 from "../../../assets/images/testimonials-02.jpg";
import testimonials03 from "../../../assets/images/testimonials-03.jpg";
import mikeDamien from "../../../assets/images/testimonials-04.jpg";
import $ from "jquery"
import arrowLeft from "../../../assets/images/arrowLeft.svg"
import arrowRight from "../../../assets/images/arrowRight.svg"


export default function Testimonials() {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            import('owl.carousel').then(() => {
                $('.owl-testimonials').owlCarousel({
                    items: 2,
                    loop: true,
                    dots: false,
                    nav: true,
                    navText: [
                        `<img src="${arrowLeft}" alt="Left Arrow" />`,
                        `<img src="${arrowRight}" alt="Right Arrow" />`
                    ],
                    autoplay: true,
                    margin: 25,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        1000: {
                            items: 2
                        }
                    }
                });
            }).catch((error) => console.error('Error loading Owl Carousel:', error));
        }, 200);

        return () => clearTimeout(timeoutId);
    }, []);


    return (
        <>
            <section className="testimonials" id="testimonials">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">
                                <img src={IconGallery} alt="" style={{ "width": "60px" }} />

                                <h4>Testimonials</h4>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="owl-testimonials owl-carousel" id="owl-testimonials" style={{ "position": "relative", "zIndex": "5" }}>
                                <div className="item">
                                    <h4>Mike Damien</h4>
                                    <span>Customer</span>
                                    <p>“The Comeback Home exceeded our expectations. Their tailored plan brought harmony back to our home. If you want real results, they’re the answer.”</p>

                                    <div className="right-image">
                                        <img src={mikeDamien} alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <h4>Elizabeth Mekonnen</h4>
                                    <span>Customer</span>
                                    <p>“Exceptional service with a genuine commitment to helping families thrive. Their personalized approach strengthened our family like never before!”</p>

                                    <div className="right-image">
                                        <img src={testimonials02} alt="" />
                                    </div>
                                </div>
                                <div className="item">
                                    <h4>Amelia Cooper</h4>
                                    <span>Customer</span>
                                    <p>“I'm so grateful for The Comeback Home. Their team was always there with advice and support. Their dedication is truly inspiring, and I highly recommend their services!”</p>

                                    <div className="right-image">
                                        <img src={testimonials03} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
