import React from 'react'
import Image5 from "../../../assets/images/Image 5.jpg"
import Image4 from "../../../assets/images/Image 4.jpg"

export default function ContactUsSection1() {
    return (
        <>
            <section className="about-us">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 about-text-warp">
                            <div className="about-text">
                                <h2>Welcome to the comeback homes where you can find your dream.</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tristique justo aliquam lacus
                                    ultrices, a feugiat urna blandit. Vivamus consequat purus eu risus tempor, sit amet rutrum
                                    justo aliquet. Donec nec orci non eros aliquet blandit eu sed magna. Cras semper lacus
                                    tempus nisl pretium, eu pretium dolor sollicitu-din. Nunc porttitor, libero a efficitur
                                    interdum, ex mi ornare quam, at tempor tortor dui ac mi. Donec blandit sit amet est non
                                    condimentum.</p>
                                <a href="#" className="dark-button">View our Listings</a>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="about-img">
                                <img src={Image5} alt="" />
                                <img className="l-img" src={Image4} alt="" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
