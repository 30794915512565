import React from 'react'
import NavMenu from "../layout/NavMenu"
import Breadcrumb from "../layout/Breadcrumb"
import Content from "../sections/the-comeback-services/Content"

export default function OurServices() {
    return (
        <>
            <NavMenu />
            <Breadcrumb breadcrumb={{ pagename: 'Our Services' }} />
            <Content />
        </>
    )
}
