import React from 'react'
import ContactSection1 from "./ContactSection1"
import ContactUsForm from "./ContactUsForm"
import FooterAuthor from "../../layout/FooterAuthor"

export default function Content() {
    return (
        <>
            <ContactSection1 />
            <ContactUsForm />
            <FooterAuthor />
        </>
    )
}
