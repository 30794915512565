import React from 'react'
import IconCheck from "../../../assets/images/IconCheck.svg"

export default function CheckAvailibilityForm() {
    return (
        <>
            <section className="simple-cta">
                <div className="container">
                    <div className="row">
                        <form id="calculate" action="" method="get">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    <div className="section-heading">
                                        <img src={IconCheck} alt="" style={{ "width": "60px" }} />
                                        <h4>Check Availibility</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-3">
                                    <fieldset>

                                        <input type="date" name="name" id="name" placeholder="Preferred Visit  Date" autoComplete="on" required />
                                    </fieldset>
                                </div>
                                <div className="col-lg-3">
                                    <fieldset>

                                        <input type="time" name="time" id="time" placeholder="Preferred Visit Time" required="" />
                                    </fieldset>
                                </div>
                                <div className="col-lg-4">
                                    <fieldset className="icon-message">

                                        <input type="subject" name="subject" id="subject" placeholder="Additional Message" autoComplete="on" />
                                    </fieldset>
                                </div>

                                <div className="col-lg-2">
                                    <fieldset>
                                        <button type="submit" id="form-submit" className="dark-button">Submit Now</button>
                                    </fieldset>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
