import React from 'react';
import NavMenu from "../layout/NavMenu";
import Content from "../sections/the-comeback-home/Content";

export default function Home() {
    return (
        <>
            <NavMenu />
            <Content />
        </>
    )
}
