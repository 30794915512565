import React from 'react'
import AccommodationOptions from "../../../assets/images/AccommodationOptions.jpg"
import transportation from "../../../assets/images/transportation-400px.jpg"
import RecreationalActivities from "../../../assets/images/Recreational_activities.jpg"

export default function OSMainSection() {
    return (
        <>
            <section className="main-services">
                <div className="container text-light">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-item">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="left-image">
                                            <img src={AccommodationOptions} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="right-text-content">
                                            <i className="fas fa-building"></i>
                                            <h4>Accommodation Options</h4>
                                            <p>You will see a bunch of free CSS templates when you search on Google. TemplateMo is the best one because it is 100% free for anyone. We do not ask you anything in return. You are free to use our templates for any purpose.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="service-item">
                                <div className="row">
                                    <div className="col-lg-6 align-self-center">
                                        <div className="left-text-content">
                                            <i className="fas fa-bus-alt"></i>
                                            <h4>Transportation assistance</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et ex massa. In ac laoreet urna. Curabitur eu enim nibh. Phasellus sodales nisi vel leo aliquam, ut tempus augue hendrerit. Pellentesque eu risus tincidunt, condimentum eros in, auctor augue. Vivamus et urna blandit, varius sapien sed.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="right-image">
                                            <img src={transportation} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="service-item last-service">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="left-image">
                                            <img src={RecreationalActivities} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="right-text-content">
                                            <i className="fas fa-football-ball"></i>
                                            <h4>Recreational activities</h4>
                                            <p>Mauris id efficitur ante, vitae ultrices metus. Donec et nunc massa. Nullam non felis dignissim, dapibus turpis semper, vulputate lorem. Nam volutpat posuere tellus, in porttitor justo interdum nec. Aenean in dapibus risus, in euismod ligula. Aliquam vel scelerisque elit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
