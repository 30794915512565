import React, { useEffect } from 'react';
import "../../../assets/css/swiper-bundle.min.css";
import homeMainModernCityBanner from "../../../assets/images/X-17-Modern-002-SLIDE.jpg";
import Swiper from 'swiper';



export default function TheCBHomeMainBanner() {
    useEffect(() => {
        var interleaveOffset = 0.7;

        var swiperOptions = {
            loop: true,
            // speed: 1000,
            // grabCursor: true,

            parallax: true,
            centeredSlides: true,
            speed: 1000,
            spaceBetween: 0,
            // effect: "slide",



            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            pagination: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            on: {
                progress: function () {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector(".slide-inner").style.transform =
                            "translate3d(" + innerTranslate + "px, 0, 0, 0)";
                    }
                },
                touchStart: function () {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                },
                setTransition: function (speed) {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        swiper.slides[i].querySelector(".slide-inner").style.transition =
                            speed + "ms";
                    }
                }
            }
        };

        let swiper;
        if (Swiper) {
            swiper = new Swiper(".swiper-container", swiperOptions);
        }
        return () => {
            if (swiper && swiper.destroy) swiper.destroy();
        };
    });

    return (
        <>
            <div className="swiper-container" id="top">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${homeMainModernCityBanner})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="header-text">

                                            <div className="buttons">
                                                <div className="brown-button">
                                                    <a href="#">schedule a visit</a>
                                                </div>
                                                <div className="light-button">
                                                    <a href="#">Virtual tours</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${homeMainModernCityBanner})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="header-text">

                                            <div className="buttons">
                                                <div className="brown-button">
                                                    <a href="#">schedule a visit</a>
                                                </div>
                                                <div className="light-button">
                                                    <a href="#">Virtual tours</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-slide">
                        <div className="slide-inner" style={{ backgroundImage: `url(${homeMainModernCityBanner})` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="header-text">

                                            <div className="buttons">
                                                <div className="brown-button">
                                                    <a href="#">schedule a visit</a>
                                                </div>
                                                <div className="light-button">
                                                    <a href="#">Virtual tours</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* pagination  */}
                {/* <div className="swiper-pagination"></div> */}

                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                    <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span className="swiper-pagination-bullet"></span>
                    <span className="swiper-pagination-bullet"></span>
                </div>

                {/* navigation */}
                <div className="swiper-button-next swiper-button-white"></div>
                <div className="swiper-button-prev swiper-button-white"></div>
            </div >
        </>
    )
}