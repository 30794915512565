import React from 'react'
import NavMenu from "../layout/NavMenu"
import Breadcrumb from "../layout/Breadcrumb"
import Content from "../sections/the-comeback-team/Content"

export default function Team() {
    return (
        <>
            <NavMenu />
            <Breadcrumb breadcrumb={{ pagename: 'Our Team' }} />
            <Content />
        </>
    )
}
