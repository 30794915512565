import React from 'react'


export default function CounterSection() {
    return (
        <>
            <div className="counter-section">
                <div className="container">
                    <div className="b-top">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="single-counter">
                                    <div className="counter-img">
                                        <img src="img/counter-icon/1.png" alt="" />
                                    </div>
                                    <div className="counter-info">
                                        <span>20 + </span>
                                        <p>Room Options</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-counter">
                                    <div className="counter-img">
                                        <img src="img/counter-icon/2.png" alt="" />
                                    </div>
                                    <div className="counter-info">
                                        <span>1000 +</span>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-counter">
                                    <div className="counter-img">
                                        <img src="img/counter-icon/3.png" alt="" />
                                    </div>
                                    <div className="counter-info">
                                        <span>25 +</span>
                                        <p>Years of experience</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-counter">
                                    <div className="counter-img">
                                        <img src="img/counter-icon/4.png" alt="" />
                                    </div>
                                    <div className="counter-info">
                                        <span>17</span>
                                        <p>Awards Wone</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
