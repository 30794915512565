import React from 'react'
import OSMainSection from "./OSMainSection"
import OurSerSection1 from "./OurSerSection1"
import OurSerSection2 from "./OurSerSection2"
import FooterAuthor from "../../layout/FooterAuthor"

export default function Content() {
    return (
        <>
            <OSMainSection />
            <OurSerSection1 />
            <OurSerSection2 />
            <FooterAuthor />
        </>
    )
}
