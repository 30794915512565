import React from 'react'

export default function Breadcrumb(props) {
    const { breadcrumb } = props
    return (
        <>
            <div className="page-heading">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-text">
                                <h2>{breadcrumb?.pagename}</h2>
                                <div className="div-dec"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
