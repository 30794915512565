import React, { useEffect } from 'react'
import { Link, NavLink } from "react-router-dom"
import headerLogo from "../../assets/images/logo_header.svg";
import $ from 'jquery';

export default function NavMenu() {

    useEffect(() => {
        $(window).on('scroll', function () {
            var scroll = $(window).scrollTop();
            var box = $('.header-text').height();
            var header = $('header').height();

            if (scroll >= box - header) {
                $("header").addClass("background-header");
            } else if (scroll < 60) {
                $("header").removeClass("background-header");
            }
        });

        // Menu Dropdown Toggle
        if ($('.menu-trigger').length) {
            $(".menu-trigger").on('click', function () {
                $(this).toggleClass('active');
                $('.header-area .nav').slideToggle(200);
            });
        }

        // Clean up event listeners on component unmount
        return () => {
            $(window).off('scroll');
            $('.menu-trigger').off('click');
        };
    }, []);

    const handleHomeClick = () => {
        // window.location.href = '/'; // This will reload the entire page
    };

    return (
        <>
            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                {/* ***** Logo Start *****  */}
                                <Link to="/" className="logo" >
                                    <img src={headerLogo} alt="" />
                                </Link>
                                {/* Logo End ***** */}
                                {/* ***** Menu Start *****  */}
                                <ul className="nav">
                                    {/* <li><NavLink to="/the-comeback-home" end>Home</NavLink></li> */}
                                    <li><NavLink to="/" end >Home</NavLink></li>
                                    <li> <NavLink to="/about-us" >About</NavLink></li>
                                    <li>  <NavLink to="/our-services" >Services</NavLink></li>
                                    <li> <NavLink to="/team" >Team</NavLink></li>
                                    <li> <NavLink to="/contact-us" >Contact Us</NavLink></li>
                                </ul>
                                <a className='menu-trigger'>
                                    <span>Menu</span>
                                </a>
                                {/* ***** Menu End ***** */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
