import React from 'react';
import faregularcirclecheck from "../../../assets/images/Icon fa-regular-circle-check.svg";
import leavingRoom from "../../../assets/images/leaving.jpg";
import $ from "jquery"

export default function Facilities() {
    $(document).on("click", ".naccs .menu div", function () {
        var numberIndex = $(this).index();

        if (!$(this).is("active")) {
            $(".naccs .menu div").removeClass("active");
            $(".naccs ul li").removeClass("active");

            $(this).addClass("active");
            $(".naccs ul").find("li:eq(" + numberIndex + ")").addClass("active");

            var listItemHeight = $(".naccs ul")
                .find("li:eq(" + numberIndex + ")")
                .innerHeight();
            $(".naccs ul").height(listItemHeight + "px");
        }
    });

    return (
        <>
            <section className="facility" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="section-heading">
                                <img src="assets/images/IconFacility.svg" alt="" style={{ "width": "60px" }} />
                                <h4>Our Facilities</h4>
                                <p>We offered various facilities at our sober living home, such
                                    as Living Rooms, Bedrooms, Commo Areas, Outdoor Spaces, Gyms, Meditation Rooms, & Gardens.            </p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="naccs">
                                <div className="tabs">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="menu">
                                                <div className="active gradient-border"><span>Living Rooms</span></div>
                                                <div className="gradient-border"><span>Bedrooms</span></div>
                                                <div className="gradient-border"><span>Common Areas</span></div>
                                                <div className="gradient-border"><span>Outdoor Spaces</span></div>
                                                <div className="gradient-border"><span>Gyms</span></div>
                                                <div className="gradient-border"><span>Meditation Rooms</span></div>
                                                <div className="gradient-border"><span>Gardens</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <ul className="nacc">
                                                <li className="active">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Stylish And Functional Living Room</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />

                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Stylish And Functional Bedrooms</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />
                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Stylish Common Areas</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />

                                                        </div>

                                                    </div>
                                                </li>


                                                <li>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Stylish Outdoor Spaces</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />

                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Stylish And Functional Gyms</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />

                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Functional Meditation Rooms</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />

                                                        </div>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="list-item">
                                                                <span className="item item-title">Stylish Gardens</span>
                                                            </div>
                                                            <p>Our rooms come in Standard and Superior, we’ve kitted out the rooms with comfortable, thick mattresses and coffee and tea-making facilities.
                                                            </p>
                                                            <br />
                                                            <div> <img src={faregularcirclecheck} alt="" className="bullets" /> Modern & Stylist furniture</div><br />
                                                            <div ><img src={faregularcirclecheck} alt="" className="bullets" /> Fully Air Conditioner</div>

                                                        </div>
                                                        <div className="col-6">
                                                            <img src={leavingRoom} alt="" />

                                                        </div>

                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
