import React, { useEffect } from 'react'
import IconGallery from "../../../assets/images/IconGallery.svg"
import Image3 from "../../../assets/images/Image 3.jpg"
import Image9 from "../../../assets/images/Image 9.jpg"
import Image5 from "../../../assets/images/Image 5.jpg"
import Image6 from "../../../assets/images/Image 6.jpg"
import Image4 from "../../../assets/images/Image 4.jpg"
import Image7 from "../../../assets/images/Image 7.jpg"
import gallaryImg10 from "../../../assets/images/gallaryImg10.jpg"
import gallaryImg11 from "../../../assets/images/gallaryImg11.jpg"
import gallaryImg12 from "../../../assets/images/gallaryImg12.jpg"
import gallaryImg13 from "../../../assets/images/gallaryImg13.jpg"
import GLightbox from "glightbox"

export default function CheckAvailibilityphotoGallery() {
    useEffect(() => {
        const lightbox = GLightbox({
            touchNavigation: true,
            loop: true,
            width: "90vw",
            height: "90vh"
        });

        // Clean up GLightbox instance on component unmount
        return () => {
            lightbox.destroy();
        };
    }, []);
    return (
        <>
            <section className="photoGallery">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="section-heading">
                            <img src={IconGallery} alt="" style={{ "width": "60px" }} />
                            <h4>Gallary</h4>
                        </div>
                    </div>
                </div>
                <div id="gallery" className="photos-grid-container gallery">


                    <div className="img-box"><a href={Image3} className="glightbox" data-glightbox="type: image"><img src={Image3} alt="image" /></a></div>
                    <div className="img-box"><a href={Image9} className="glightbox" data-glightbox="type: image"><img src={Image9} alt="image" /></a></div>
                    <div className="img-box"><a href={Image5} className="glightbox" data-glightbox="type: image"><img src={Image5} alt="image" /></a></div>
                    <div className="img-box"><a href={Image6} className="glightbox" data-glightbox="type: image"><img src={Image6} alt="image" /></a></div>
                    <div className="img-box"><a href={Image4} className="glightbox" data-glightbox="type: image"><img src={Image4} alt="image" /></a></div>
                    <div className="img-box"><a href={gallaryImg10} className="glightbox" data-glightbox="type: image"><img src={gallaryImg10} alt="image" /></a></div>

                    <div className="img-box"><a href={Image6} className="glightbox" data-glightbox="type: image"><img src={Image6} alt="image" /></a></div>

                    <div id="multi-link" className="img-box">
                        <a href={Image5} className="glightbox" data-glightbox="type: image">
                            <img src={Image5} alt="image" />
                            <div className="transparent-box">
                                <div className="caption">
                                    +3
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
                <div id="more-img" className="extra-images-container hide-element">
                    <a href={gallaryImg11} className="glightbox" data-glightbox="type: image"><img src={gallaryImg11} alt="image" /></a>
                    <a href={gallaryImg12} className="glightbox" data-glightbox="type: image"><img src={gallaryImg12} alt="image" /></a>
                    <a href={gallaryImg13} className="glightbox" data-glightbox="type: image"><img src={gallaryImg13} alt="image" /></a>

                </div>
            </section>
        </>
    )
}
