import React from 'react'
import Image7 from "../../../assets/images/Image 7.jpg"
import $ from "jquery"

export default function OurSerSection2() {
    $(document).on("click", ".naccs .menu div", function () {
        var numberIndex = $(this).index();

        if (!$(this).is("active")) {
            $(".naccs .menu div").removeClass("active");
            $(".naccs ul li").removeClass("active");

            $(this).addClass("active");
            $(".naccs ul").find("li:eq(" + numberIndex + ")").addClass("active");

            var listItemHeight = $(".naccs ul")
                .find("li:eq(" + numberIndex + ")")
                .innerHeight();
            $(".naccs ul").height(listItemHeight + "px");
        }
    });

    return (
        <>
            <section className="service-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-heading">

                                <h4>Services and Amenities</h4>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="naccs">
                                <div className="tabs">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="menu">
                                                <div className="active gradient-border"><span>Accommodation</span></div>
                                                <div className="gradient-border"><span>Meal plans</span></div>
                                                <div className="gradient-border"><span>Transportation </span></div>
                                                <div className="gradient-border"><span>Recreational </span></div>
                                                <div className="gradient-border"><span>Support groups</span></div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <ul className="nacc">
                                                <li className="active">
                                                    <div>
                                                        <div className="left-image">
                                                            <img src={Image7} alt="" />
                                                        </div>
                                                        <div className="right-content">
                                                            <h4>Accommodation Options</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Fitness center</span>
                                                            <span>- Free private parking</span>
                                                            <span className="last-span">-  A terrace </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div className="left-image">
                                                            <img src={Image7} alt="" />
                                                        </div>
                                                        <div className="right-content">
                                                            <h4>Accommodation Options</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Fitness center</span>
                                                            <span>- Free private parking</span>
                                                            <span className="last-span">-  A terrace </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div className="left-image">
                                                            <img src={Image7} alt="" />
                                                        </div>
                                                        <div className="right-content">
                                                            <h4>Accommodation Options</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Fitness center</span>
                                                            <span>- Free private parking</span>
                                                            <span className="last-span">-  A terrace </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div className="left-image">
                                                            <img src={Image7} alt="" />
                                                        </div>
                                                        <div className="right-content">
                                                            <h4>Accommodation Options</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Fitness center</span>
                                                            <span>- Free private parking</span>
                                                            <span className="last-span">-  A terrace </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div className="left-image">
                                                            <img src={Image7} alt="" />
                                                        </div>
                                                        <div className="right-content">
                                                            <h4>Accommodation Options</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Fitness center</span>
                                                            <span>- Free private parking</span>
                                                            <span className="last-span">-  A terrace </span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div>
                                                        <div className="left-image">
                                                            <img src={Image7} alt="" />
                                                        </div>
                                                        <div className="right-content">
                                                            <h4>Accommodation Options</h4>
                                                            <p>Etiam id ligula risus. Fusce fringilla nisl nunc, nec rutrum lectus cursus nec. In blandit nibh dolor, at rutrum leo accumsan porta. Nullam pulvinar eros porttitor risus condimentum tempus.</p>
                                                            <span>- Fitness center</span>
                                                            <span>- Free private parking</span>
                                                            <span className="last-span">-  A terrace </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
