import React from 'react'
import OurTeamSection from "./OurTeamSection"
import CounterSection from "./CounterSection"
import FooterAuthor from "../../layout/FooterAuthor"

export default function Content() {
    return (
        <>
            <OurTeamSection />
            <CounterSection />
            <FooterAuthor />
        </>
    )
}
