import React from 'react'

export default function ContactSection1() {
    return (
        <>
            <section className="map">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="map">
                                <div style={{ maxWidth: '100%', overflow: 'hidden', color: 'red', width: '100%', height: '450px' }}>
                                    <div id="canvas-for-googlemap" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
                                        <iframe style={{ height: '100%', width: '100%', border: '0' }} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=11325+Twin+Cities+Rd,+Galt,+CA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" allowFullScreen>
                                        </iframe>
                                    </div>
                                    <a className="our-googlemap-code" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="make-map-data">premium bootstrap themes</a>
                                    {/* <style>#canvas-for-googlemap img{max - width:none!important;background:none!important;font-size: inherit;font-weight:inherit;}</style> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="info-item">
                                        <i className="fa fa-envelope"></i>
                                        <h4>Email Address</h4>
                                        <a href="mailto:info@thecomebackhome.com">info@thecomebackhome.com </a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-item">
                                        <i className="fa fa-phone"></i>
                                        <h4>Phone Number</h4>
                                        <p className="font-weight-normal"> <a className="text-light" href="tel:+15309662266">530-966-2266</a></p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="info-item">
                                        <i className="fa fa-map-marked-alt"></i>
                                        <h4>Address</h4>
                                        <a href="#">1325 Twin Cities Rd, Galt, CA</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
