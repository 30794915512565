import React from 'react';
import metroLocationCity from "../../assets/images/Icon metro-location-city.svg";
import awesomeEnvelopeOpenText from "../../assets/images/Icon awesome-envelope-open-text.svg";
import featherphoneCall from "../../assets/images/Icon feather-phone-call.svg";
import { NavLink } from "react-router-dom";

export default function FooterAuthor() {
    return (
        <>
            <footer>
                <div className="topFooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 text-center border-right">
                                <img src={metroLocationCity} alt="" />
                                <p className="font-weight-normal"> 11325 Twin Cities Rd, Galt, CA</p>
                                <p className="text-dark">Our Address</p>
                            </div>
                            <div className="col-lg-4 text-center border-right">
                                <img src={awesomeEnvelopeOpenText} alt="" />
                                <p><a className="text-light" href="mailto:info@thecomebackhome.com"> info@thecomebackhome.com</a></p>
                                <p className="text-dark">Our Mailbox</p>
                            </div>
                            <div className="col-lg-4 text-center">
                                <img src={featherphoneCall} alt="" />
                                {/* <p className="font-weight-normal"> 916-494-4262</p> */}
                                <p className="font-weight-normal"> <a className="text-light" href="tel:+15309662266">530-966-2266</a></p>
                                <p className="text-dark">Our Phone</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <span>
                                    <NavLink to="/" end className={({ isActive }) => (isActive ? 'text-light' : '')}> Home</NavLink>
                                    <NavLink to="/about-us" className={({ isActive }) => (isActive ? 'text-light' : '')}>About</NavLink>
                                    <NavLink to="/our-services" className={({ isActive }) => (isActive ? 'text-light' : '')}>Services</NavLink>
                                    <NavLink to="/team" className={({ isActive }) => (isActive ? 'text-light' : '')}>Team</NavLink>
                                    <NavLink to="/contact-us" className={({ isActive }) => (isActive ? 'text-light' : '')}>Contact Us</NavLink>
                                </span>
                                <p>Copyright © 2024 <a title="The Come Back Home" href="#" target="_blank">thecomebackhome</a> All Rights Reserved.</p>
                                <p>Designed & Developed by <a href="https://netlynxinc.com/" target="_blank" rel="noopener noreferrer">Netlynx Inc</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
<p>Desgined & Developed by <a title="The Come Back Home" href="#" target="_blank">thecomebackhome</a> All Rights Reserved.</p>